<template lang="pug">
  .login
    login-form(help='/login/password-forgot')
</template>

<script>
export default {
  name: 'LoginLogin',
  computed: {
    i18nScope: () => 'views.login.login',
  },

  created() {
    document.title = 'Portal do Servidor'
  },
}
</script>
